import { Dispatch, SetStateAction } from "react";

interface ErrorToggles {
  showReasonError: boolean;
  showAttachmentError: boolean;
  showExceededHours: boolean;
}

interface Leave {
  mandatoryReason: boolean;
  reasonCode?: string;
  mandatoryAttachment: boolean;
  attachments?: any[]; // Replace `any` with a proper type if available
}

const validateLeave = (
  leave: Leave,
  leaveUnits: any, // Consider replacing `any` with an appropriate type
  setErrorToggles: Dispatch<SetStateAction<ErrorToggles>>,
  isApprove: boolean
): boolean => {
  const newErrorToggles: ErrorToggles = {
    showExceededHours: false,
    showReasonError: leave.mandatoryReason && !leave.reasonCode,
    showAttachmentError:
      leave.mandatoryAttachment && (!leave.attachments || leave.attachments.length === 0),
  };

  if (isApprove) {
    leaveUnits.map((unit: any) => {
      if (unit.totalHoursAppliedFor > unit.hoursWorkScheduled) {
        newErrorToggles.showExceededHours = true;
      }
    });
  }

  setErrorToggles(newErrorToggles);
  const isValid = !newErrorToggles.showReasonError && !newErrorToggles.showAttachmentError;
  return isValid;
};

const reset = (
  setErrorToggles: Dispatch<SetStateAction<ErrorToggles>>
): void => {
  // Reset all errors to false.
  setErrorToggles({
    showExceededHours: false,
    showReasonError: false,
    showAttachmentError: false
  });
};

const validateExceededHours = (selectedLeave: any, errorToggles: any, setErrorToggles: Dispatch<SetStateAction<ErrorToggles>>) => {
  let showExceededHours = false;
  if (selectedLeave.startDate && selectedLeave.endDate) {
    if (selectedLeave.startDate > selectedLeave.endDate) {
      showExceededHours = true;
    }
  }
  setErrorToggles({ ...errorToggles, showExceededHours });
}

const valiedateReason = (selectedLeave: any, errorToggles: any, setErrorToggles: Dispatch<SetStateAction<ErrorToggles>>) => {
  let showReasonError = selectedLeave.mandatoryReason && !selectedLeave.reasonCode
  setErrorToggles({ ...errorToggles, showReasonError });
}

const validateAttachment = (selectedLeave: any, errorToggles: any, setErrorToggles: Dispatch<SetStateAction<ErrorToggles>>) => {
  let showAttachmentError = selectedLeave.mandatoryAttachment && (!selectedLeave.attachments || selectedLeave.attachments.length === 0)
  setErrorToggles({ ...errorToggles, showAttachmentError });
}

export default {
  validateLeave,
  valiedateReason,
  validateAttachment,
  validateExceededHours,
  reset
};
