import { Select, Input } from 'antd';
import theme from '@/styles/customTheme';
import { useMemo } from 'react';
import { CloseCircleFilled } from '@ant-design/icons';
const { TextArea } = Input;

interface ReasonSectionProps {
  selectedLeave: any;
  selectedLeaveType: any;
  errorToggles: any
  onSelectReason: (value: string) => void;
  setSelectedLeave: (leave: any) => void;
}

const ReasonSection: React.FC<ReasonSectionProps> = ({
  selectedLeave,
  // leaveReasonOptions,
  selectedLeaveType,
  errorToggles,
  onSelectReason,
  setSelectedLeave
}) => {
    const leaveReasonOptions = useMemo(() => {
      if (!selectedLeaveType.reasons) {
        return [];
      }
      const reasonArr = selectedLeaveType.reasons.map((reason: any) => ({
        value: reason.reasonCode,
        label: reason.description,
      }))
      reasonArr.unshift({ value: '', label: '' });
      return reasonArr;
    }, [selectedLeaveType.reasons]);
  
  return (
    <>
      <section style={{ padding: "0 10px" }}>
        {errorToggles.showReasonError && (
          <span className="leave-validate-error">
            <span className="mr-2">
              <CloseCircleFilled />
            </span>
            Please provide a reason why you are taking leave.
          </span>
        )}
        <div className="bold">
          Reason
          {selectedLeave.mandatoryReason && (
            <span style={{ color: "#e90909", marginLeft: "4px" }}>
              * required
            </span>
          )}
        </div>
        <Select
          style={{ width: "100%", marginTop: "4px" }}
          className='custom-select'
          options={leaveReasonOptions}
          onChange={onSelectReason}
          value={selectedLeave.reasonCode}
          notFoundContent="Please select a leave type first "
          allowClear

        />
      </section>
      {selectedLeave.showPartDayReason && (
        <section style={{ padding: "10px 10px 0" }}>
          <div className="bold">Part Day - Start/Finish Times</div>
          <TextArea
            style={{
              marginTop: "8px",
              boxShadow: theme.color.shadow1,
              border: "none",
            }}
            value={selectedLeave.partDayReason}
            onChange={(e) =>
              setSelectedLeave({
                ...selectedLeave,
                partDayReason: e.target.value,
              })
            }
            rows={4}
            placeholder="Enter your part day reason"
          />
        </section>
      )}
    </>
  );
};

export default ReasonSection;