import React, { useEffect, useRef } from 'react';
import { Typography } from 'antd';

const { Text } = Typography;

interface LeaveTypeSelectionProps {
  applyOptions: any;
  selectedLeave: any;
  chooseLeaveType: (leaveType: any) => void;
}

const LeaveTypeSelection: React.FC<LeaveTypeSelectionProps> = ({ applyOptions, selectedLeave, chooseLeaveType }) => {
  const selectedCardRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (selectedCardRef.current) {
      selectedCardRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'center'
      });
    }
  }, [selectedLeave.leaveCode]);
  return (
    <section style={{ padding: "0 10px" }}>
      <Text strong>Leave Type</Text>
      <div className="leave-type-row">
        {applyOptions.leaveType?.map((leaveType: any) => {
          return (
            <div
              ref={leaveType.leaveCode === selectedLeave.leaveCode ? selectedCardRef : null}
              className={`leave-type-card ${
                leaveType.leaveCode === selectedLeave.leaveCode
                  ? "selected-type-card"
                  : ""
              } `}
              style={{ background: "white" }}
              key={leaveType.leaveCode}
              onClick={() => chooseLeaveType(leaveType)}
            >
              <div>{leaveType.description}</div>
              {
                leaveType.displayBalance && (
                  <div style={{ fontSize: "12px" }}>
                    {leaveType.currentUnitsBal}{' '}
                    {`${leaveType.unitType === "H" ? "Hours" : "Days"} available`}
                  </div>
                )
              }
             
              
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default LeaveTypeSelection;
