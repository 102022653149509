import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { Card, Typography, Button, DatePicker, Input, Divider, Row, Col, Select, InputNumber, Upload, notification, Spin, Modal } from 'antd';
import { useTeamLeaveStore } from '@/store/teamLeaveStore';
import { CloseOutlined, LoadingOutlined, PaperClipOutlined, SearchOutlined } from '@ant-design/icons';
import theme from '@/styles/customTheme';
import remote from '@/remote/remote';
import { useMyLeaveStore } from '@/store/myLeaveStore';
import dayjs from 'dayjs';
import { LIST_DATE_FORMAT, REQUEST_DATE_FORMAT } from '../constant';
import DateRange, { Days } from './DateRange';
import { EmployeeSelection } from './applyLeaveComponents/EmployeeSelection';
import PositionSelection from './applyLeaveComponents/PositionSelection';
import LeaveTypeSelection from './applyLeaveComponents/LeaveTypeSelection';
import AttachmentSection from './applyLeaveComponents/AttachmentSection';
import CommentsSection from './applyLeaveComponents/CommentsSection';
import ApproverSelection from './applyLeaveComponents/ApproverSelection';
import ReasonSection from './applyLeaveComponents/ReasonSection';
import validation from './validateLeave';

const { Title, Text } = Typography;
const { TextArea } = Input;
const DatePickerFormat = 'dddd, DD MMMM YYYY';

interface ApplyLeaveProps {
  isTeamLeave: boolean;
  leave?: any;
} 

export interface ApplyLeaveRef {
  clear: () => void;
  validate: (isApprove: boolean) => boolean;
}

const ApplyLeave = forwardRef<ApplyLeaveRef, ApplyLeaveProps>((props, ref) => {
  const [employeeList, setEmployeeList] = useState<any[]>([]);
  // const [applyLeaveSettings, setApplyLeaveSettings] = useState<any>({});
  const [applyOptions, setApplyOptions] = useState<any>({});
  const [selectedLeave, setSelectedLeave] = useState<any>({});
  const [selectedLeaveType, setSelectedLeaveType] = useState<any>({});
  const [selectedEmployee, setSelectedEmployee] = useState<any>({});
  const fileInputRef = useRef(null);
  const [attachmentLoading, setAttachmentLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [toggleLeaveInDay, setToggleLeaveInDay] = useState(false);
  const [errorToggles, setErrorToggles] = useState<any>({
    showReasonError: false,
    showAttachmentError: false,
  })
  // const [errorToggles, setErrorToggles] = useState<any>(errorToggles)
  // const [leaveReasonOptions, setLeaveReasonOption] = useState<any>({});
  const user = window.Affinity?.user

  const teamLeaveStore = useTeamLeaveStore();
  const state = teamLeaveStore.state;
  const dispatch = teamLeaveStore.dispatch;
  const myStore = useMyLeaveStore();
  const myState = myStore.state;
  const updateMyStoreLeave = myStore.updateSelectedLeave;
  const updateTeamStoreLeave = teamLeaveStore.updateSelectedLeave;
  const myDispatch = myStore.dispatch;

  useImperativeHandle(ref, () => ({
    clear,
    validate
  }));

  useEffect(() => {
    if (props.isTeamLeave) {
      dispatch({type: 'SET_SELECTED_LEAVE_DETAIL', payload: selectedLeave})
    } else {
      myDispatch({type: 'SET_SELECTED_LEAVE', payload: selectedLeave})
    }
  }, [selectedLeave])

  useEffect(() => {
    loadManagerEmployeeList();
  }, [state.employees])

  const approversOptions = useMemo(() => {
    if (!applyOptions.positions) return [];
    const approvers = applyOptions.positions.find((position: any) => position.positionCode === selectedLeave.positionCode)?.submittedTos
    if (!approvers) return [];
    return approvers.map((approver: any) => ({
      positionCode: approver.positionCode,
      emailAddress: approver.emailAddress,
      value: approver.employeeNo,
      label: approver.employeeName
    }))
    // return approvers;
    // update approver when position changed
  }, [selectedLeave.positionCode])

  useEffect(() => {
    // const targetLeave = fetchDataInit();
    fetchDataInit();
    validation.reset(setErrorToggles);
  }, [])

  const fetchDataInit = async function () {
    setDataLoading(true)
    if (!props.leave) { // new leave
      await initNewLeave()
      setDataLoading(false)
    } else { // edit leave
      setIsEdit(true);
      let employeeData
      if (props.isTeamLeave) {
        setSelectedEmployee(props.leave.employeeNo);
        employeeData = await remote.getLeaveManagerEmployeeConfig(props.leave.employeeNo)
        
        //
        props.leave.reply = state.selectedLeaveDetail.reply
        props.leave.managerComment = state.selectedLeaveDetail.reply
      } else {
        employeeData = myState.leaveConfig;
      }
      const res = await remote.getEmployeeLeaveBalance(props.leave.employeeNo, {})
      const leaveBalance = res.componentBalances[0].codeBalances
      setLeaveDataBySource(employeeData, leaveBalance);
      setSelectedLeave({
        ...props.leave,
        submittedTo: props.leave.authorisations[0].submittedTo
      });
    }
    setDataLoading(false)  
  }

  const setLeaveDataBySource = (leaveConfigData: any, leaveBalance: any) => {
    const data = leaveConfigData;
    leaveBalance.map((balance: any) => {
        const leaveCode = data.leaveCodes.find((leaveCode: any) => leaveCode.leaveCode === balance.leaveCode)
        if (!leaveCode) return;
        leaveCode.displayBalance = balance.displayBalance
    })
    setApplyOptions({
      leaveType: data.leaveCodes,
      positions: data.positions,      
    })
    // CurrentUnitsBal
    // Description
    // LeaveCode
    // setApplyLeaveSettings({
    //   singlePosition: data.positions.length === 1,
    //   defaultPosition: data.positions[0],
    //   defaultApprover: data.positions[0].submittedTos[0],
    // })

    if (props.leave) {
      const leaveType = data.leaveCodes.find((leave: any) => leave.leaveCode === props.leave.leaveCode)
      setSelectedLeaveType(leaveType);
    }
  }
  const initNewLeave = async () => {
    setIsEdit(false);
      if (props.isTeamLeave) {
        loadManagerEmployeeList()
        return;
      } else {
        // 
        const res = await remote.getEmployeeLeaveBalance(user.employeeNo, {})
        const leaveBalance = res.componentBalances[0].codeBalances
        setLeaveDataBySource(myState.leaveConfig, leaveBalance)
        if (myState.leaveConfig.positions.length === 1) {
          setSelectedLeave({
            ...selectedLeave,
            employeeNo: user.employeeNo,
            submittedBy: user.employeeNo,
            // leaveCode: myState.leaveConfig.leaveCodes[0].leaveCode,
            positionCode: myState.leaveConfig.positions[0].positionCode,
            positionTitle: myState.leaveConfig.positions[0].positionTitle,
            submittedTo: myState.leaveConfig.positions[0].submittedTos[0].employeeNo,
            authorisations: [myState.leaveConfig.positions[0].submittedTos[0]]
          })
        }
      }
  }

  const loadManagerEmployeeList = () => {
    const mappedEmployee = state.employees.map((employee) => {
      return {
        value: employee.employeeNo,
        label: employee.employeeName,
        isDirect: employee.isDirect
      }
    })
    setEmployeeList(mappedEmployee);
  }

  const onSelectEmployee = async (employeeNo: any) => {
    setDataLoading(true)
    setSelectedEmployee(employeeNo);
    const newEmp = employeeList.find((emp) => emp.value === employeeNo)
    try {
      const employeeData = await remote.getLeaveManagerEmployeeConfig(employeeNo)
      const res = await remote.getEmployeeLeaveBalance(employeeNo, {})
      const leaveBalance = res.componentBalances[0].codeBalances
      setLeaveDataBySource(employeeData, leaveBalance);
  
      if (employeeData.positions.length === 1) {
        setSelectedLeave({
          ...selectedLeave,
          employeeNo: employeeNo,
          submittedBy: employeeNo,
          positionCode: employeeData.positions[0].positionCode,
          positionTitle: employeeData.positions[0].positionTitle,
          submittedTo: employeeData.positions[0].submittedTos[0].employeeNo,
          authorisations: [employeeData.positions[0].submittedTos[0]]
        })
      }
    } catch (err) {
      console.error(err)
    }
    
    setDataLoading(false)
  }

  const onSelectPosition = (position: any) => {
    setSelectedLeave({
      ...selectedLeave,
      positionCode: position,
      submittedTo: applyOptions.positions.find((pos: any) => pos.positionCode === position)?.submittedTos[0].employeeNo
    })
  }

  const onDateRangeChange = (dateRange: any, dateList: Days[], totalDays: number, totalHours: number) => {
    let showPartDayReason = false;
    dateList.map((date: any) => {
      if (date.totalDaysAppliedFor < 1 ) {
        // showPartDayReason = true;
        date.showPartDayWarning = true;
        date.partDayReason = '';
      } else {
        date.showPartDayWarning = false;
      }
    })
    if (totalDays < 1 && totalDays > 0) {
      // date.partDayReason = '';
      showPartDayReason = true;
    }
    if (!dateList || dateList.length === 0) return;
    setSelectedLeave({
      ...selectedLeave,
      dateFrom: dateRange.dateFrom,
      dateTo: dateRange.dateTo,
      days: dateList,
      totalHours: totalHours,
      totalDays: totalDays,
      showPartDayReason: showPartDayReason,
      partDayReason: showPartDayReason ? selectedLeave.partDayReason : null
    })

    if(props.isTeamLeave) {
      // SET_SELECTED_LEAVE_UNIT
      dispatch({type: 'SET_SELECTED_LEAVE_UNIT', payload: dateList})
    } else {
      myDispatch({type: 'SET_SELECTED_LEAVE_UNIT', payload: dateList})
    }
    updateLeaveCodeBalance(dateRange.dateTo)
  }

  const updateLeaveCodeBalance =  async (dateTo: string) => {
    let params = {
      "dateTo": dateTo,
    }
    const res = await remote.getEmployeeLeaveBalance(selectedLeave.employeeNo, params)    
    const updatedLeaveType = applyOptions.leaveType.map((leaveType: any) => {
      const leaveBalance = res.componentBalances[0].codeBalances.find((balance: any) => balance.leaveCode === leaveType.leaveCode)
      if (leaveBalance) {
        leaveType.currentUnitsBal = leaveBalance.unitType === "H" ? leaveBalance.totalHours : leaveBalance.totalDays;
      }
      return leaveType
    })
    setApplyOptions({
      ...applyOptions,
      leaveType: updatedLeaveType
    })
  }
  const onSelectReason = (reason: string) => {
    if (!reason) {
      setSelectedLeave({
        ...selectedLeave,
        reasonCode: null,
        reasonDescription: null
      })
      return;
    }
    const selectedReason = selectedLeaveType.reasons.find((val: any) => val.reasonCode === reason);
    setSelectedLeave({
      ...selectedLeave,
      reasonCode: selectedReason.reasonCode,
      reasonDescription: selectedReason.reasonDescription
    })
  }

  const chooseLeaveType = (leaveType: any) => {
    setSelectedLeave({
      ...selectedLeave,
      leaveCode: leaveType.leaveCode,
      unitType: leaveType.unitType,
      description: leaveType.description,
      mandatoryAttachment: leaveType.mandatoryAttachment,
      mandatoryReason: leaveType.mandatoryReason,
    })
    setSelectedLeaveType(leaveType);
  }

  const updateAttachmentFiles = (files: any) => {
    setSelectedLeave({
      ...selectedLeave,
      attachments: files
    })
  }

  const validate = (isApprove: boolean) => {
    return validation.validateLeave(selectedLeave, selectedLeave.days, setErrorToggles, isApprove);
  }

  const clear = () => {
    let tempLeave = {...selectedLeave}
    tempLeave.dateFrom = ''
    tempLeave.dateTo = ''
    tempLeave.days = []
    tempLeave.totalHours = null
    tempLeave.totalDays = null
    tempLeave.showPartDayReason = false

    tempLeave.positionCode = null
    tempLeave.positionTitle = null
    tempLeave.attachments = null
    tempLeave.comment = null,
    tempLeave.reasonCode = null

    setSelectedLeave(tempLeave)
    fetchDataInit();
  }
  
  const onSaveLeaveInday = async () => {
    setDataLoading(true)
    if (props.isTeamLeave) {      
      await updateTeamStoreLeave(selectedLeave.statusCode)
    } else {
      await updateMyStoreLeave(selectedLeave.statusCode)
    }
    setDataLoading(false)
  }

  return (
    <Spin indicator={<LoadingOutlined style={{ fontSize: 42 }} spin />} 
      spinning={dataLoading}
      size="large" 
      >
      <div style={{}}>
      {/* {props.isTeamLeave && !isEdit && (
          <section style={{ padding: "0 10px", marginTop: "20px" }}>
            <div>Employee</div>
            <Select
              style={{ width: "100%", marginTop: "8px" }}
              size="large"
              showSearch
              options={employeeList}
              filterOption={(input, option) =>
                (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
              }
              value={selectedEmployee}
              onChange={onSelectEmployee}
              placeholder="Select an employee"
            ></Select>
          </section>
        )} */}
        <EmployeeSelection
          isTeamLeave={props.isTeamLeave}
          isEdit={isEdit}
          employeeList={employeeList}
          selectedEmployee={selectedEmployee}
          onSelectEmployee={onSelectEmployee}
          leave={props.leave}
        />
        <Divider className="leave-divider" />
        <PositionSelection
          applyOptions={applyOptions}
          selectedLeave={selectedLeave}
          onSelectPosition={onSelectPosition}
        />
        <Divider className="leave-divider" />
        <LeaveTypeSelection
          applyOptions={applyOptions}
          selectedLeave={selectedLeave}
          chooseLeaveType={chooseLeaveType}
        />
        <Divider className="leave-divider" />
        {
          selectedLeave.leaveCode
          ? <DateRange selectedLeave={selectedLeave}
             onDateRangeChange={onDateRangeChange}
             isTeamLeave={props.isTeamLeave}
             isEdit={isEdit}
             saveLeaveInday={onSaveLeaveInday}
             errorToggles={errorToggles}></DateRange>
          : <h3 style={{marginLeft: '10px'}}>Please select Leave Type First</h3>
        }
        <Divider className="leave-divider" />
        <ReasonSection 
          selectedLeave={selectedLeave}
          selectedLeaveType={selectedLeaveType}
          errorToggles={errorToggles}
          onSelectReason={onSelectReason}
          setSelectedLeave={setSelectedLeave}
        />
       
        <Divider className="leave-divider" />
        <AttachmentSection
          // attachmentLoading={attachmentLoading}
          selectedLeave={selectedLeave}
          errorToggles={errorToggles}
          updateAttachmentFiles={updateAttachmentFiles}
          // handleAttachClick={handleAttachClick}
          // handleFileChange={handleFileChange}
          // confirmDelete={confirmDelete}
        />
        <Divider className="leave-divider" />
        <CommentsSection
          isTeamLeave={props.isTeamLeave}
          isEdit={isEdit}
          selectedLeave={selectedLeave}
          setSelectedLeave={setSelectedLeave}
        />
        <Divider className="leave-divider" />
        <ApproverSelection
          approversOptions={approversOptions}
          selectedLeave={selectedLeave}
          setSelectedLeave={setSelectedLeave}
        />
      </div>
    </Spin>
  );
});

export default ApplyLeave;
