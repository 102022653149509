import React, { useEffect, useState } from 'react';
import { Button, Tabs, Card, Row, Col, Divider, Typography, Badge } from 'antd';
import { FilterOutlined, TeamOutlined, CheckCircleOutlined, CalendarOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { leaveStatusByCode, LIST_DATE_FORMAT } from '../constant';

const { Title, Text } = Typography;
const { TabPane } = Tabs;

type LeaveCardProps = {
  leave: any;
  index: number;
  showDetail: (leave: any) => void;
  showSubmitted: boolean;
}

const TeamLeaveCard = (props: LeaveCardProps) => {
  const { leave, index } = props;
  const [formattedDateFrom, setFormattedDateFrom] = useState<string>('');
  const [formattedDateTo, setFormattedDateTo] = useState<string>('');

  useEffect(() => {
    setFormattedDateFrom(dayjs(leave.dateFrom).format(LIST_DATE_FORMAT));
    setFormattedDateTo(dayjs(leave.dateTo).format(LIST_DATE_FORMAT));
  }, [leave]);

  const showDetail = () => {
    props.showDetail(leave);
  }
  return (
    <>
      <Card key={index} className={
        `card-${leaveStatusByCode(leave.statusCode)}`
      }>
        <Row justify="space-between">
          <Text>{leave.codeDescription}</Text>
          <Text className="card-status">{leaveStatusByCode(leave.statusCode)}</Text>
        </Row>
        <Title level={4}>{leave.employeeName}</Title>
        <Row
          justify="space-between"
          align="middle"
          style={{ marginTop: "10px" }}
        >
          <Col span={4}>
            <div className="number-box">
              <Title level={4}>{leave.totalHours}</Title>
              <Text strong style={{ fontSize: "12px" }}>
                hours
              </Text>
            </div>
          </Col>
          <Col style={{ textAlign: "right" }} >
            <CalendarOutlined />
            <div className='inline-block' style={{ verticalAlign: 'top', marginLeft: '10px'}}>
              {formattedDateFrom === formattedDateTo ? (
                <div>{formattedDateFrom}</div>
              ) : (
                <>
                  <div>{formattedDateFrom}</div>
                  <div><span>- </span><span style={{whiteSpace: 'nowrap'}}>{formattedDateTo}</span></div>
                </>
              )}
            </div>
            {
              props.showSubmitted &&
              <div>Submitted Date: {leave.timeSubmitted}</div>
            }
            
          </Col>
        </Row>

        <Button
          type="link"
          style={{ marginTop: "10px", padding: "0", height: "auto" }}
          onClick={showDetail}
        >
          More Actions
        </Button>
      </Card>
    </>
  );
}

export default TeamLeaveCard;
