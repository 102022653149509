import React, { useEffect } from 'react';
import { Select, Typography } from 'antd';

const { Text } = Typography;

interface ApproverSelectionProps {
  approversOptions: any[];
  selectedLeave: any;
  setSelectedLeave: (leave: any) => void;
}

const ApproverSelection: React.FC<ApproverSelectionProps> = ({
  approversOptions,
  selectedLeave,
  setSelectedLeave,
}) => {
  const updateSubmittedTo = (value: any) => {
    const approver = approversOptions.find((approver) => approver.value === value)
    if (!approver) console.error('Approver not found')
    setSelectedLeave({...selectedLeave, 
      authorisations: [
        {
          positionCode: approver.positionCode,
          employeeNo: approver.value,
          employeeName: approver.label,
          emailAddress: approver.emailAddress
        }
      ],
      submittedTo: value
    })
  }
  return (
    <section style={{ marginTop: "10px", padding: "0 10px" }}>
      <Text strong>Approver</Text>
      <Select
        options={approversOptions}
        value={selectedLeave.submittedTo}
        style={{ width: "100%", marginTop: "8px" }}
        placeholder="Select an employee"
        onChange={(value) => updateSubmittedTo(value)}
      ></Select>
    </section>
  );
};

export default ApproverSelection;
