import React, { useMemo } from 'react';
import { Button, Tabs, Card, Row, Col, Divider, Typography, Badge } from 'antd';
import { FilterOutlined, TeamOutlined, CheckCircleOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { leaveStatusByCode, LIST_DATE_FORMAT } from '../constant';

const { Title, Text } = Typography;
const { TabPane } = Tabs;


type LeaveCardProps = {
  leave: any;
  index: number;
  moreAction: (selectedLeave: any) => void;
}

const MyLeaveCard = (props: LeaveCardProps) => {
  const { leave, index } = props;
  const formattedDateFrom = dayjs(leave.dateFrom).format(LIST_DATE_FORMAT);
  const formattedDateTo = dayjs(leave.dateTo).format(LIST_DATE_FORMAT);
  const moreAction = () => {
    props.moreAction(leave);
  }

  const statusName = useMemo(() => {
    let name  = leaveStatusByCode(leave.statusCode)
    if (name) {
      return name 
    } else {
      return leave.status
    }
  }, [leave])
  return (
    <>
      <Card key={index} style={{borderLeft: '3px #1268b3 solid'}}>
        <Row justify="space-between">
          <Text>{leave.codeDescription}</Text>
          <Text className='card-status'>{statusName}</Text>
        </Row>
        
        <span>
          <Title level={4}>{leave.totalHours} Hours</Title>
        </span>
        <div>
          <Text>{`${formattedDateFrom} - ${formattedDateTo}`}</Text>
        </div> 
        <div className='font-grey-m'>Reason: {leave.reasonDescription}</div>
        <Button type="link" style={{marginTop: '10px', padding: '0', height: 'auto'}} 
          onClick={moreAction}>
          More Actions
        </Button>
      </Card>
    </>
  
  )
}

export default MyLeaveCard;
