// leave balance component 

import { useEffect, useState } from "react";
import { Card, Divider, Row, Typography } from "antd";
import remote from "@/remote/remote";
import dayjs from "dayjs";
import { ESTIMATE_LEAVE_CODE_LIST } from "../constant";
import { useMyLeaveStore } from "@/store/myLeaveStore";

const { Title, Text } = Typography;

const MyLeaveBalance = () => {
  const [leaveBalance, setLeaveBalance] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const user = window.Affinity?.user;
  const myStore = useMyLeaveStore();
  const myState = myStore.state;

  useEffect(() => {
    const today = dayjs().format('DD-MMM-YYYY');
    // const params = {
    //   dateTo: today,
    //   includeIndirect: false,
    //   pageNo: 1
    // };
    const params = {}

    setLoading(true);
    remote.getEmployeeLeaveBalance(user.employeeNo, params)
      .then((res) => {
        setLeaveBalance(res.componentBalances[0].codeBalances.filter((balance: any) => {
          return myState.leaveCode.find((code: any) => code.leaveCode === balance.leaveCode) !== undefined && 
                ESTIMATE_LEAVE_CODE_LIST.includes(balance.leaveCode);
        }
        ));
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <Card loading={loading}>
      <Title level={4}>Balance Summary</Title>
      <Divider />
      {leaveBalance.map((leave) => (
        <div key={leave.leaveCode}>
          <Row justify="space-between" align="middle">
            <Text style={{ fontSize: '18px' }}>{leave.codeDescription}</Text>
            {leave.unitType === 'H' ? (
              <div>
                <div>{leave.totalHours} Hours</div>
                <div style={{ color: '#8a99ab' }}>{leave.totalDays} Days</div>
              </div>
            ) : (
              <div>
                <div>{leave.totalDays} Days</div>
                <div style={{ color: '#8a99ab' }}>{leave.totalHours} Hours</div>
              </div>
            )}
          </Row>
          <Divider />
        </div>
      ))}
    </Card>
  );
};

export default MyLeaveBalance;