import { Button, Checkbox, DatePicker, Grid, Row, Select } from "antd"
import { useEffect, useMemo, useState } from "react"
import dayjs from "dayjs"
import { DISPLAY_DATE_FORMAT, LEAVE_REQUEST_DATE_FORMAT, LeaveStatus } from "../constant"
import { useTeamLeaveStore } from "@/store/teamLeaveStore"
import { useMyLeaveStore } from "@/store/myLeaveStore"

export const DefaultMyHistoryFilter = {
  status: 0,
  leaveType: "all",
  orderBy: "DateFrom",
  dateFrom:  dayjs().subtract(1, 'year').format(), 
  dateTo: null,
  isAscending: false
}

export interface MyLeaveHistoryFilterProps {
  onFilterChange: (filter: typeof DefaultMyHistoryFilter) => void
}

export const STATUS_OPTIONS = [
  { label: 'All', value: -1 },
  { label: 'Pending', value: 0 },
  { label: 'Declined', value: 2 },
  { label: 'Approved', value: 3 },
  { label: 'Cancelled', value: 6 },
];

export const ORDERBY_OPTIONS = [
  { label: 'Start Date', value: 'DateFrom' },
  { label: 'End Date', value: 'DateTo' },
  { label: 'Submitted Date', value: 'DateSubmitted' },
  { label: 'Unit', value: 'Hours' },
];

const TeamLeaveHistoryFilter = (props: MyLeaveHistoryFilterProps) => {
  const [filter, setFilter] = useState(DefaultMyHistoryFilter)
  const { state, dispatch } = useMyLeaveStore(); 

  const leaveTypeOptions = useMemo(() => {
    const leaveCodes = state.leaveCode.map((leaveCode: any) => ({ label: leaveCode.description, value: leaveCode.leaveCode }))
    leaveCodes.unshift({ label: 'All', value: 'all' })
    return leaveCodes
  }, [state.leaveCode])
  const handleChange = (key: string, value: any) => {
    const newFilter = {
      ...filter,
      [key]: value
    }
    setFilter(newFilter)
    props.onFilterChange(newFilter)
  }

  return (
    <>
      <div className="team-leave-history-filter">
        <div>Status</div>
        <Select
          defaultValue={LeaveStatus.Pending}
          value={filter.status}
          onChange={(value) => handleChange("status", value)}
          options={STATUS_OPTIONS}
        ></Select>
      
        <div>Leave Type</div>
        <Select
          defaultValue="all"
          style={{maxWidth: 170}}
          value={filter.leaveType}
          onChange={(value) => handleChange("leaveType", value)}
          options={leaveTypeOptions}
        ></Select>
      
        <div>Sort By</div>
        <Select
          defaultValue="Submitted Date"
          value={filter.orderBy}
          onChange={(value) => handleChange("orderBy", value)}
          options={ORDERBY_OPTIONS}
        ></Select>
        <div>Ascending</div>
        <Checkbox
          checked={filter.isAscending}
          onChange={(e) => handleChange("isAscending", e.target.checked)}
        />

        <div>Date From</div>
        <DatePicker
          value={filter.dateFrom ? dayjs(filter.dateFrom) : null}
          format={DISPLAY_DATE_FORMAT}
          onChange={(date) => handleChange("dateFrom", date ? date.toISOString() : null)}
        />
        
        <div>Date To</div>
        <DatePicker
          value={filter.dateTo ? dayjs(filter.dateTo) : null}
          format={DISPLAY_DATE_FORMAT}
          onChange={(date) => handleChange("dateTo", date ? date.toISOString() : null)}
        />
      </div>
      
    </>
  )
}

export default TeamLeaveHistoryFilter;