// this.leaveStatusFilter = new Element('select', { 'class': 'history-filter-select status-filter inline' }).adopt(
//   new Element('option', { 'value': '0', 'html': 'All', 'id': null }),
//   new Element('option', { 'value': '1', 'html': 'Approved', 'id': '3' }),
//   new Element('option', { 'value': '2', 'html': 'Declined', 'id': '2' }),
//   new Element('option', { 'value': '3', 'html': 'Pending', 'id': '0' }),
//   new Element('option', { 'value': '4', 'html': 'Cancelled', 'id': '6' })

import dayjs from "dayjs"

export enum LeaveStatus {
  All = -1,
  Approved = 3,
  Declined = 2,
  Pending = 0,
  Cancelled = 6,
  CancellRequest= 7,
}

export const LIST_DATE_FORMAT = 'ddd, DD MMM YYYY'
export const REQUEST_DATE_FORMAT = 'YYYY-MM-DD'
export const LEAVE_REQUEST_DATE_FORMAT = 'DD/MMM/YYYY'  // 18/Dec/2024
export const DISPLAY_DATE_FORMAT = 'DD/MM/YYYY'
export const ESTIMATE_LEAVE_CODE_LIST = ['07', '09', '10', '11', '12', '13']

export const leaveStatusByCode = (code: number) => {
  // code = Number(code)
  switch (code) {
    case LeaveStatus.Approved:
      return 'Approved'
    case LeaveStatus.Declined:
      return 'Declined'
    case LeaveStatus.Pending:
      return 'Pending'
    case LeaveStatus.Cancelled:
      return 'Cancelled'
    case LeaveStatus.CancellRequest:
      return 'Cancel Request'
  }
}

export const DefaultTeamLeaveHistoryParam = {
  PageNo: 1,
  StatusCode: 0,
  DateFrom: dayjs().subtract(1, 'year').format(LEAVE_REQUEST_DATE_FORMAT),
  OrderBy: 'DateSubmitted',
  isAscending: true,
}