import React, { useEffect } from 'react';
import { Button, DatePicker, InputNumber, Row, Typography, Tooltip } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import theme from '@/styles/customTheme';
import { LIST_DATE_FORMAT, REQUEST_DATE_FORMAT } from '../constant';
import remote from '@/remote/remote';
import { useTeamLeaveStore } from '@/store/teamLeaveStore';
import { useMyLeaveStore } from '@/store/myLeaveStore';
import { FaPlane } from "react-icons/fa";
import { CloseCircleFilled } from '@ant-design/icons';

const { RangePicker } = DatePicker;
const { Title, Text } = Typography;
const DatePickerFormat = 'dddd, DD MMMM YYYY';

export interface Days {
  date: string;
  isPublicHoliday: boolean;
  publicHolidayName: string;
  totalDaysAppliedFor: number;
  totalHoursAppliedFor: number;
  positionUnits: any[];
  hoursWorkScheduled?: number;
}

interface PositionUnits {
  hoursWorkScheduled: number;
}

interface DateRangeProps {
  selectedLeave: any
  isTeamLeave: boolean
  isEdit: boolean
  errorToggles: any
  saveLeaveInday: () => void
  onDateRangeChange: (dateRange: any, dateList: any[], totalDays: number, totalHours: number) => void
}

const DateRange: React.FC<DateRangeProps> = (props) => {
  const [dateRange, setDateRange] = React.useState<any>({})
  const [isEditDate, setIsEditDate] = React.useState(false)
  const [dateList, setDateList] = React.useState<any[]>([])
  const [totalDays, setTotalDays] = React.useState(0)
  const [totalHours, setTotalHours] = React.useState(0)
  const [totalWeeks, setTotalWeeks] = React.useState(0)
  const [leaveInDayToggle, setLeaveInDayToggle] = React.useState(false)
  const user = window.Affinity?.user;
  const {state, dispatch} = useTeamLeaveStore();
  const myStore = useMyLeaveStore();
  const myState = myStore.state;
  const myDispatch = myStore.dispatch;

  useEffect(() => {
    let leaveUnit = props.isTeamLeave ? state.selectedLeaveUnit : myState.selectedLeaveUnit
    if (!leaveUnit || !leaveUnit.units) return;
    const newDateList = leaveUnit.units?.map((unit: any) => {
      return {
        date: unit.date,
        isPublicHoliday: unit.isPublicHoliday,
        publicHolidayName: unit.publicHolidayName,
        totalDaysAppliedFor: unit.daysAppliedFor,
        totalHoursAppliedFor: unit.hoursAppliedFor,
        // positionUnits: state.selectedLeaveUnit.positionUnits,
        hoursWorkScheduled: unit.hoursWorkScheduled
      }
    })
    setDateList(newDateList)
  }, [])

  useEffect(() => {
    if (props.selectedLeave.dateFrom && props.selectedLeave.dateTo) {
      const obj = {
        dateFrom: props.selectedLeave.dateFrom,
        dateTo: props.selectedLeave.dateTo
      }
      
      if (dateRange.dateFrom !== obj.dateFrom || dateRange.dateTo !== obj.dateTo) {
        setDateRange(obj)
      }
      
    } else if (!props.selectedLeave.dateFrom && !props.selectedLeave.dateTo) {
      setDateRange({
        dateFrom: dayjs().toISOString(),
        dateTo: dayjs().toISOString()
      })
    }
    
  }, [props.selectedLeave.dateFrom, props.selectedLeave.dateTo])

  useEffect(() => {
    if (dateRange.dateFrom && dateRange.dateTo && props.selectedLeave.leaveCode) {
      // if (dayjs(dateRange.dateFrom).diff(dayjs(dateRange.dateTo), 'day') > 0) {
      //   setDateRange({
      //     ...dateRange,
      //     dateTo: dateRange.dateFrom,
      //   })
      // }
      getCalculatedUnit()
    }
    // props.onDateRangeChange(dateRange, dateList)
  }, [dateRange, props.selectedLeave.leaveCode])

  useEffect(() => {
    if (!dateRange.dateFrom || !dateRange.dateTo) return;
    getCalculatedUnit(true)
  }, [props.selectedLeave.employeeNo])

  useEffect(() => {
    if (!dateList || dateList.length === 0) return;
    const totalDays = dateList.reduce((acc: number, date: Days) => {
      if (!date) return acc;
    
      // Handle hours calculation
      if (date.hoursWorkScheduled && date.hoursWorkScheduled > 0) {
        const daysFromHours = Math.min(
          (date.totalHoursAppliedFor || 0) / date.hoursWorkScheduled,
          1
        );
        return acc + daysFromHours;
      }
    
      // Fallback to direct days value
      return acc + (date.totalDaysAppliedFor || 0);
    }, 0);
    setTotalDays(Number.isNaN(totalDays) ? 0 : Math.ceil(totalDays * 100) / 100);


    const totalHours = dateList.reduce((acc: number, date: any) => {
      return acc + date.totalHoursAppliedFor
    }, 0)
    setTotalHours(totalHours)
    props.onDateRangeChange(dateRange, dateList, totalDays, totalHours)
  }, [dateList])
  
  const getCalculatedUnit = (isNewDateList: boolean = false) => {
    let requestUserNo = props.selectedLeave.employeeNo || user.employeeNo;
    let oldDateList = isNewDateList ? [] : dateList;

    const prarms = {
      employeeNo: requestUserNo,
      dateFrom: dayjs(dateRange.dateFrom).format(REQUEST_DATE_FORMAT),
      dateTo: dayjs(dateRange.dateTo).format(REQUEST_DATE_FORMAT),
      leaveCode: props.selectedLeave.leaveCode
    }

    remote.getCalculateLeaveUnits(prarms)
    .then(res => {
      setTotalWeeks(res.totalWeeksAppliedFor)
      // setTotalDays(res.totalDaysAppliedFor)
      // setTotalHours(res.totalHoursAppliedFor)
      // Create new date list with preserved values
      const newDateList = (res.days as Days[]).map((newDate: any) => {
        // Find matching date in old dateList
        const existingDate = oldDateList.find(oldDate => {
          if (oldDate.date === newDate.date) {
            return true;
          }
        });
          
        // If match found, preserve old values
        if (existingDate) {
          return {
            ...existingDate,
            positionCode: newDate.positionUnits[0].positionCode,
          }
        }

        // If no match, use new values
        return {
          ...newDate,
          hoursWorkScheduled: newDate.positionUnits[0].hoursWorkScheduled
        };
      });

      setDateList(newDateList);
    })
  }

  const updateDateListInput = (e: number, index: number) => {
    const value = e;
    const newDateList = dateList.map((date: any, i: number) => {
      if (i === index) {
        return {
          ...date,
          totalHoursAppliedFor: value,
          totalDaysAppliedFor: date.hoursWorkScheduled > 0 ? Math.min(value / date.hoursWorkScheduled, 1) : 0
        }
      }
      return date;
    })
    setDateList(newDateList);
  }

  const updateScheduleHours = (e: number, index: number) => {
    const value = e;
    const newDateList = dateList.map((date: any, i: number) => {
      if (i === index) {
        return {
          ...date,
          hoursWorkScheduled: value,
          totalDaysAppliedFor: value > 0 ? Math.min(date.totalHoursAppliedFor / value, 1) : 0
        }
      }
      return date;
    })
    setDateList(newDateList);
  }
  
  const onStartDateChange = (val: Dayjs) => {
    if (dayjs(dateRange.dateTo).startOf('day').diff(val.startOf('day'), 'day') < 0) {
      setDateRange({
        dateFrom: val.startOf('day').toISOString(),
        dateTo: val.startOf('day').toISOString()
      })
    } else {
      setDateRange({
        ...dateRange,
        dateFrom: val.startOf('day').toISOString()
      })
    }
  }
  
  const onEndDateChange = (val: Dayjs) => {
    if (dayjs(dateRange.dateFrom).startOf('day').diff(val.startOf('day'), 'day') > 0) {
      setDateRange({
        dateFrom: val.startOf('day').toISOString(),
        dateTo: val.startOf('day').toISOString()
      })
    } else {
      setDateRange({
        ...dateRange,
        dateTo: val.startOf('day').toISOString()
      })
    }
  }

  function toggleLeaveInday(): void {
    setIsEditDate(!isEditDate)
    if (isEditDate && props.isEdit) {
      props.saveLeaveInday()
    }
  }

  return (
    <section style={{ marginTop: "10px", padding: "0 10px" }}>
      <div>
        <Text strong>Start Date</Text>
      </div>
      <div style={{ marginTop: "4px" }}>
        <DatePicker
          size="large"
          onChange={onStartDateChange}
          className="apply-date-picker"
          value={dayjs(dateRange.dateFrom)}
          format={{
            format: DatePickerFormat,
          }}
        />
      </div>
      <div style={{ marginTop: "10px" }}>
        <div>
          <Text strong>End Date</Text>
        </div>
        <div style={{ marginTop: "4px" }}>
          <DatePicker
            size="large"
            onChange={onEndDateChange}
            className="apply-date-picker"
            value={dayjs(dateRange.dateTo)}
            format={{
              format: DatePickerFormat,
            }}
          />
        </div>
      </div>
      {isEditDate && (
        <>
          <div
            style={{
              marginTop: "10px",
              display: "grid",
              gridTemplateColumns: "100px 1fr",
            }}
          >
            <Text>Date</Text>
            <div style={{ textAlign: "right" }}>
              <Text strong className="mr-2 date-range-label">
                Days
              </Text>
              <Text strong className="mr-2 date-range-label">
                Hours
              </Text>
              <Text
                strong
                className="date-range-label"
                style={{ width: "70px" }}
              >
                Schedule
              </Text>
            </div>
          </div>
          <div style={{ marginTop: "10px" }}>
            {dateList.map((date: any, index: number) => (
              <React.Fragment key={`${date.date}-${index}`}>
                <Row
                  style={{ marginTop: "10px" }}
                  key={index}
                  className="date-row"
                  justify="space-between"
                  align="middle"
                >
                  <Text>
                    {date.isPublicHoliday ? (
                      <Tooltip
                        title={date.publicHolidayName}
                        trigger={["hover", "click"]}
                      >
                        <FaPlane
                          style={{
                            marginRight: "4px",
                            verticalAlign: "middle",
                          }}
                        />
                      </Tooltip>
                    ) : (
                      ""
                    )}

                    <span style={{ verticalAlign: "middle" }}>
                      {dayjs(date.date).format(LIST_DATE_FORMAT)}
                    </span>
                  </Text>
                  <span>
                    <InputNumber
                      disabled
                      style={{
                        width: "60px",
                        background: "#f7f9fe",
                        color: "rgba(0, 0, 0, 0.88)",
                      }}
                      type="number"
                      value={Math.ceil(date.totalDaysAppliedFor * 100) / 100}
                    ></InputNumber>
                    <InputNumber
                      style={{ width: "60px", marginLeft: "8px" }}
                      type="number"
                      value={date.totalHoursAppliedFor}
                      onChange={(e) => updateDateListInput(e, index)}
                    ></InputNumber>
                    <InputNumber
                      disabled={!props.isEdit || !props.isTeamLeave}
                      style={{
                        width: "60px",
                        marginLeft: "8px",
                        marginRight: "8px",
                        background: (props.isEdit && props.isTeamLeave) ? "#00000" : "#f7f9fe",
                        color: "rgba(0, 0, 0, 0.88)",
                      }}
                      type="number"
                      value={date.hoursWorkScheduled}
                      onChange={(e) => updateScheduleHours(e, index)}
                    ></InputNumber>
                  </span>
                </Row>
                {date.totalDaysAppliedFor < 1 && date.totalDaysAppliedFor > 0 && (
                  <Row justify="end">
                    <Text style={{ color: "#faa73d" }}>Partial leave day</Text>
                  </Row>
                )}
                {date.totalHoursAppliedFor > date.hoursWorkScheduled && (
                  <Row justify="end">
                    <Text style={{ color: "#faa73d" }}>
                      Hours exceeds Schedule amount
                    </Text>
                  </Row>
                )}
              </React.Fragment>
            ))}
          </div>
        </>
      )}
      {/* <Row>
          {props.errorToggles.showExceededHours && (
            <span className="leave-validate-error">
              <span className="mr-2">
                <CloseCircleFilled />
              </span>
              Unable to approve. Leave Hours exceed Scheduled Hours.
            </span>
          )}
        </Row> */}
      <Row justify="space-between" align="middle" style={{ marginTop: "10px" }}>
        <Text style={{ fontSize: "16px" }}>
          Total {totalDays} days / {Math.ceil(totalHours * 100) / 100} hours
        </Text>
        <Button
          type="text"
          style={{ color: theme.color.gray1 }}
          onClick={() => toggleLeaveInday()}
        >
          {isEditDate ? 'Save Units' : 'Edit Units'}
        </Button>
      </Row>

      {/* <Row style={{ marginTop: '10px' }}>
          <Text style={{ fontSize: '16px' }}>
            Balance 56.25 hours
          </Text>
        </Row> */}
    </section>
  );
};

export default DateRange;