import { Checkbox, Select, Typography } from 'antd';
import { useEffect, useMemo, useState } from 'react';
const { Text } = Typography;

interface EmployeeSelectionProps { isTeamLeave: boolean; isEdit: boolean; employeeList: any[]; selectedEmployee: any; onSelectEmployee: (value: any) => void; leave: any; }

export const EmployeeSelection = ({
  isTeamLeave,
  isEdit,
  employeeList,
  selectedEmployee,
  onSelectEmployee,
  leave,
}: EmployeeSelectionProps) => {
  const [includeIndirect, setIncludeIndirect] = useState(false);

  const filteredEmployeeList = useMemo(() => {
    if (!includeIndirect) {
      return employeeList.filter((employee) => employee.isDirect);
    }
    return employeeList;
  }, [employeeList, includeIndirect]);

  const toggleIndirect = () => {
    setIncludeIndirect(!includeIndirect);
  };
  if (isEdit) {
    return (
      <section style={{ padding: "0 10px", marginTop: "20px" }}>
        <Text strong>Employee</Text>
        <div style={{ marginTop: "8px" }}>{leave?.employeeName}</div>
      </section>
    );
  }

  if (isTeamLeave) {
    return (
      <>
        <section style={{ padding: "0 10px", marginTop: "20px" }}>
          <Text strong>Employee</Text>
          <Select
            style={{ width: "100%", marginTop: "8px" }}
            size="large"
            showSearch
            options={filteredEmployeeList}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            value={selectedEmployee}
            onChange={onSelectEmployee}
            placeholder="Select an employee"
          />
        </section>
        <section style={{ padding: "0 10px", marginTop: "10px" }}>
          <Checkbox checked={includeIndirect} onChange={toggleIndirect}>Include indirect reports</Checkbox>
        </section>
      </>
    );
  }

  // my leave
  return (
    <section style={{ padding: "0 10px", marginTop: "20px" }}>
       <Text strong>Employee</Text>
      <div style={{ marginTop: "8px" }}>{leave?.employeeName}</div>
    </section>
  );
};