import React, { useEffect, useMemo, useState } from 'react';
import { Button, Card, Col, Collapse, DatePicker, DatePickerProps, List, notification, Row, Select, Spin, Typography } from 'antd';
const { Title } = Typography;
import dayjs, { Dayjs } from 'dayjs';
import remote from '@/remote/remote';
import { useTeamLeaveStore } from '@/store/teamLeaveStore';
import { LoadingOutlined } from '@ant-design/icons';
import { DISPLAY_DATE_FORMAT, ESTIMATE_LEAVE_CODE_LIST } from '../constant';
import theme from '@/styles/customTheme';


const TeamEstimateLeave: React.FC = () => {
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const [showList, setShowList] = useState<boolean>(false);
  const [employeeSelectedDate, setEmployeeSelectedDate] = useState<Dayjs>(dayjs());
  const [selectedEmployee, setSelectedEmployee] = useState<number | null>(0);
  const [searchedEmployee, setSearchedEmployee] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [employeeCodeBalances, setEmployeeCodeBalances] = useState<any[]>([]);
  const [selectedLeaveCode, setSelectedLeaveCode] = useState<string | null>(null);
  const [leaveCodes, setLeaveCodes] = useState<any[]>([]);
  const [includeInDirect, setIncludeInDirect] = useState<boolean | null>(false);

  const { state, dispatch } = useTeamLeaveStore(); 

  const user = window.Affinity?.user;
  const selectedLeaveCodeDescription = useMemo(() => {
    const selectedLeave = leaveCodes.find((leaveCode) => leaveCode.value === selectedLeaveCode);
    return selectedLeave ? selectedLeave.label : '';
  }, [selectedLeaveCode, leaveCodes]);

  const employeeOptions = useMemo(() => {
    let employees = state.employees.map((employee) => ({ label: employee.employeeName, value: employee.employeeNo }))
    employees.unshift({label: 'All Direct and Indirect Employees', value: 'indirect'});
    employees.unshift({label: 'All Direct Employees', value: 'direct'});
    return employees
  }, [state.employees]);
  
  useEffect(() => {
    // filter stat.leaveCodes use availableLeaveCodes
    const leaveCodes = state.leaveCodes.filter((leaveCode) => 
      ESTIMATE_LEAVE_CODE_LIST.includes(leaveCode.leaveCode));
    setLeaveCodes(leaveCodes.map((leaveCode) => ({
      label: leaveCode.description,
      value: leaveCode.leaveCode
    })));
  }, [state.leaveCodes]);

  const handleEmployeeDateChange: DatePickerProps['onChange'] = (date: any, dateString) => {
    // setEmployeeSelectedDate(dateString as string);
    setEmployeeSelectedDate(date as Dayjs);
  }

  const onShowEmployeeEstimate = async () => {
    if (!validateEstimateLeave()) return;

    const dateTo = employeeSelectedDate.format('DD-MMM-YYYY');
    setIsLoading(true);
    let param = {
      "dateTo": dateTo,
      "includeIndirect": includeInDirect,
      "employeeNo": selectedEmployee,
      "leaveCode": selectedLeaveCode,
      "pageNo": 1
    }

    try {
      const res = await remote.managerTeamLeaveBalance(user.employeeNo, param)
      const codeOptions = res.leaveConfigOptions;
      // console.log('res', res.data.componentBalances[0].codeBalances);
      // setLeaveBalance(res.componentBalances[0].codeBalances)
      setEmployeeCodeBalances(res.projectedLeave.map((item: any) => {
        return {
          ...item,
        }
      }));
      setSearchedEmployee(selectedEmployee || 0);
      setShowList(true);
    } catch (error) {
      console.log('error', error);
    }
    setIsLoading(false);
  }
  const validateEstimateLeave = () => {
    if (!employeeSelectedDate) {
      notification.error({
        message: 'Please select a date'
      })
      return false;
    }

    if (!selectedLeaveCode) {
      notification.error({
        message: 'Please select a leave code'
      })
      return false;
    }

    return true
  }

  const onSelectEmployee = (value: number | string) => {
    if (value === 'direct') {
      setIncludeInDirect(false)
      setSelectedEmployee(0);
    } else if (value === 'indirect') {
      setIncludeInDirect(true)
      setSelectedEmployee(0);
    } else {
      setIncludeInDirect(true)
      setSelectedEmployee(value as number);
    }
    
  }

  function getTotalDisplay(item: any): React.ReactNode {
    const totalVal = item.calcUnit === "H" ? item.totalProjectedHours : item.totalProjectedDays
    if (totalVal < 0) {
      return <span style={{ color: theme.color.redMobile}}>- {Math.abs(totalVal)}</span>
    } else {
      return totalVal
    }
  }

  return (
    <Card style={{ borderRadius: '8px', marginTop: '20px'}}>
        <Title level={4}>Team Estimated Leave</Title>
        <Row style={{marginTop: 16}}>
          <Select placeholder='Select Employee'
            defaultValue={'direct'}
            style={{ width: 280}}
            options={employeeOptions}
            onChange={onSelectEmployee}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            showSearch
          ></Select>
        </Row>
        <Row style={{marginTop: 10}}>
          <Select placeholder='Select Leave Code'
            style={{ width: 280}}
            options={leaveCodes}
            onChange={(value) => setSelectedLeaveCode(value)}
            allowClear
            >

          </Select>
        </Row>
        <Row align='middle' style={{marginTop: 10}}>
          <DatePicker
            style={{ width: 280}}
            value={employeeSelectedDate}
            onChange={handleEmployeeDateChange} 
            minDate={dayjs()}/>
            <div className='estimate-leave-button'>
              <Button type="primary"
              onClick={onShowEmployeeEstimate}>
              Show
             </Button>
            </div>
          
        </Row>
        <Spin indicator={<LoadingOutlined style={{ fontSize: 42 }} spin />} 
        spinning={isLoading}
        size="large" >
        {showList && (
          <Collapse
            ghost 
            style={{ marginTop: 16, minHeight: isLoading ? '200px' : 'auto'  }}
            className='estimate-leave-collapse'
            items={employeeCodeBalances.map((item) => ({
              key: item.leaveCode,
              label: <Row>
                {
                  searchedEmployee === 0 
                  ?  <Col span={12}>{item.employeeName}</Col>
                  :  <Col span={12}>{selectedLeaveCodeDescription}</Col>
                }
                <Col span={12} style={{ textAlign: 'right'}}>{item.totalProjectedHours}</Col>
              </Row>,
              children: 
                <div>
                  <Row style={{ marginBottom: '10px', fontWeight: 'bold'}}>
                    <Col span={18}>Breakdown</Col>
                    <Col span={6} style={{ textAlign: 'right'}}>{item.calcUnit === 'H' ? 'Hours' : 'Days'}</Col>
                  </Row>
                  <Row style={{ marginBottom: '10px'}}>
                    <Col span={18}>Leave balance at last period end	</Col>
                    <Col span={6} style={{ textAlign: 'right'}}>{item.leaveBalance}</Col>
                  </Row>
                  <Row style={{ marginBottom: '10px'}}>
                    <Col span={12}>Add leave accruals</Col>
                    <Col span={12} style={{ textAlign: 'right'}}>+ {(item.accruedProjectedLeave).toFixed(2)}</Col>
                  </Row>
                  {item.leaveItems && (
                    <List
                      dataSource={item.leaveItems}
                    grid={{ gutter: 10, column: 1 }}
                    renderItem={(leaveItem: any) => (
                      <List.Item>
                        <Row>
                          {
                            leaveItem?.isCancelledOrDeclinedLeavePaidInAdvance ?
                            <>
                              <Col span={12}>Credit cancelled / declined leave booked on {dayjs(leaveItem.dateFrom).format(DISPLAY_DATE_FORMAT)}</Col> 
                              <Col span={12} style={{ textAlign: 'right'}}>+ {leaveItem.units}</Col>
                            </> :
                            <>
                              <Col span={12}>Subtract Leave booked on {dayjs(leaveItem.dateFrom).format(DISPLAY_DATE_FORMAT)}</Col>
                              <Col span={12} style={{ textAlign: 'right', color: theme.color.redMobile}}>- {leaveItem.units}</Col>
                            </>
                          }
                        </Row>
                      </List.Item>
                      )}> 
                    </List>
                  )}
                  <Row style={{ marginBottom: '10px', fontWeight: 'bold'}}>
                    <Col span={18}>Total estimated leave available on {employeeSelectedDate ? employeeSelectedDate.format('DD/MM/YYYY') : '-'}</Col>
                    <Col span={6} style={{ textAlign: 'right'}}>{getTotalDisplay(item)}</Col>
                  </Row>
                </div>
              
          }))}/>
        )}
        </Spin>
      </Card>
  );
};

export default TeamEstimateLeave;